function UploadButton({ callbackUpload }) {
    return (
        <span className="imgButton" onClick={callbackUpload}>
            <svg
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                className="fileIcon"
                width="1rem"
                height="1rem"
            >
                <path d="M14 2H6c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V8l-6-6zm1 7V3.5L18.5 9H15zm-5 2h6v2h-6v-2zm0 4h6v2h-6v-2z" />
            </svg>
        </span>
    );
}

export default UploadButton;
