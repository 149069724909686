import {useDrag} from "react-dnd";

function KursAuswahl() {
    const [{isKursDragging}, dragKurs] = useDrag(
        () => ({
            type: 'neuer_kurs',
            item: {
                type: 'neuer_kurs',
            },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            })
        }),
        []
    );

    return(
        <>
            <div className="neuerElement neuerKurs auswahllisteLine" ref={dragKurs}><p>Neuer Kurs</p></div>
        </>
    );
}

export default KursAuswahl;
