import React from 'react'
import {useDrag, useDrop} from 'react-dnd'
import ToggleButton from '../buttons/ToggleButton';
import EditButton from '../buttons/EditButton';
import DelButton from '../buttons/DelButton';
import {createCategory, createCourse, updateCategory} from "../../api_connectors/crmApiConnector";
import Kurs from "./Kurs";
import ButtonPlaceholder from "../buttons/ButtonPlaceholder";

const Thema = ({categoryItem, setEditModalItem, setUploadModalItem, setDeleteModalItem, dropItemToSibling, dropItemToContainer, updateData})=>{
    const [{isDragging}, drag] = useDrag(
        () => ({
            type: 'thema',
            item: {
                type: 'thema',
                id: categoryItem.id,
                item: categoryItem,
            },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            })
        }),
        []
    );
    const [{isOver, canDrop}, drop] = useDrop({
        accept: ['thema', 'neues_thema', 'kurs', 'neuer_kurs', 'evento_kurs'],
        drop: async (item, monitor) => {
            if (item.type === 'thema') {
                await dropItemToSibling(item, categoryItem);
            }
            if (item.type === 'kurs') {
                await dropItemToContainer(item, categoryItem);
            }
            if (item.type === 'neues_thema') {
                return await createCategory({
                    parent_category_id: categoryItem.parent_category_id,
                    name: "Neues Thema",
                    active_flag: true,
                    color: null,
                    column: categoryItem.column,
                }).then(() => {
                    updateData();
                });
            }
            if (item.type === 'neuer_kurs') {
                return await createCourse({
                    parent_category_id: categoryItem.id,
                    name: "Neuer Kurs",
                    active_flag: true,
                    html_text: "Neuer Kurs",
                    evento_id: null,
                }).then(() => {
                    updateData();
                });
            }
            if (item.type === 'evento_kurs') {
                return await createCourse({
                    parent_category_id: categoryItem.id,
                    name: item.kurs_name,
                    active_flag: true,
                    html_text: null,
                    evento_id: item.kurs_id,
                }).then(() => {
                    updateData();
                });
            }
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    });

    if (categoryItem.deleted) {
        return <></>;
    }

    const title = categoryItem.name;
    const kursItems = categoryItem.items;

    const themaCallbackApiToggle = async () => {
        categoryItem.active_flag = !categoryItem.active_flag;
        return await updateCategory(categoryItem.id, categoryItem);
    }

    return(
        <div className="planer-theme-grouping">
            <div
                ref={(e) => { drag(e); drop(e); }}
                className={
                    "planer-line planer-entry " +
                    (kursItems.length > 0 ? "planer-entry-expandable" : "planer-entry-disabled") +
                    (canDrop ? " can-drop" : "") + (isOver ? " is-over" : "")
                }
                style={{ opacity: isDragging ? 0.5 : 1 }}
            >
                <p style={{maxWidth: 'calc(100% - 10rem)'}}>
                    {title || "(Kein Titel)"}
                    <span className="planer-line-addition-content">
                        {
                            kursItems.length > 0 ? `(${kursItems.length})` : String.fromCharCode(0x2205)
                        }
                    </span>
                    <span className="planer-line-addition">
                        <ToggleButton isActive={categoryItem.active_flag} callbackApiToggle={themaCallbackApiToggle}/>
                        <EditButton callbackEdit={() => {
                            setEditModalItem(categoryItem)
                        }} canEdit={true}/>
                        <ButtonPlaceholder/>
                        <DelButton callbackDelete={() => {
                            setDeleteModalItem(categoryItem)
                        }}/>
                    </span>
                </p>
            </div>
            {kursItems.map((kursItem) => (
                <Kurs
                    key={kursItem.id}
                    kursItem={kursItem}
                    setEditModalItem={setEditModalItem}
                    setUploadModalItem={setUploadModalItem}
                    setDeleteModalItem={setDeleteModalItem}
                    dropItemToSibling={dropItemToSibling}
                    dropItemToContainer={dropItemToContainer}
                    updateData={updateData}
                />
            ))}
        </div>
    );
};

export default Thema;
