const crmApiConfig = {
    // apiRoot: 'https://idmapi.ddev.site/admin_api/v1',
    // apiRoot: 'https://127.0.0.1:58303/admin_api/v1',
    apiRoot: 'https://idm-api.mxm.agency/admin_api/v1',
    // uploadsRoot: 'https://idmapi.ddev.site/uploads',
    uploadsRoot: 'https://idm-api.mxm.agency/uploads',
    token: 'eY5xZ9pN3qF7mJ2kL8wT1uA4vC6bH0rD',
};

export default crmApiConfig;
