function ButtonPlaceholder({ callbackUpload }) {
    return (
        <span className="imgButton" onClick={callbackUpload}>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1 1" className="empty" width="1rem" height="1rem">
            </svg>
        </span>
    );
}

export default ButtonPlaceholder;
