import Gruppe from "./Gruppe";
import ToggleButton from '../buttons/ToggleButton';
import EditButton from '../buttons/EditButton';
import DelButton from '../buttons/DelButton';
import {createCategory, updateCategory} from "../../api_connectors/crmApiConnector";
import {useDrag, useDrop} from "react-dnd";
import {randomColor} from "../../framework/util";
import ButtonPlaceholder from "../buttons/ButtonPlaceholder";

const Kategorie = ({categoryItem, setEditModalItem, setUploadModalItem, setDeleteModalItem, dropItemToSibling, dropItemToContainer, updateData}) => {
    const [{isDragging}, drag] = useDrag(
        () => ({
            type: 'kategorie',
            item: {
                type: 'kategorie',
                id: categoryItem.id,
                item: categoryItem,
            },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            })
        }),
        []
    );
    const [{isOver, canDrop}, drop] = useDrop({
        accept: ['kategorie', 'neue_kategorie', 'gruppe', 'neue_gruppe'],
        drop: async (item, monitor) => {
            if (item.type === 'kategorie') {
                await dropItemToSibling(item, categoryItem);
            }
            if (item.type === 'gruppe') {
                await dropItemToContainer(item, categoryItem);
            }
            if (item.type === 'neue_kategorie') {
                return await createCategory({
                    parent_category_id: null,
                    name: "Neue Kategorie",
                    active_flag: true,
                    color: randomColor(),
                    column: categoryItem.column,
                }).then(() => {
                    updateData();
                });
            }
            if (item.type === 'neue_gruppe') {
                return await createCategory({
                    parent_category_id: categoryItem.id,
                    name: "Neue Gruppe",
                    active_flag: true,
                    color: null,
                    column: categoryItem.column,
                }).then(() => {
                    updateData();
                });
            }
        },
        collect: (monitor) => ({
            isOver: !!monitor.isOver(),
            canDrop: !!monitor.canDrop(),
        }),
    });

    if (categoryItem.deleted) {
        return <></>;
    }

    const title = categoryItem.name;
    const color = categoryItem.color;
    const groups = categoryItem.items;

    const categoryStyle = {
        '--cat-color': color,
    };

    const callbackApiToggle = async () => {
        categoryItem.active_flag = !categoryItem.active_flag;
        return await updateCategory(categoryItem.id, categoryItem);
    }

    return(
        <div
            className="bpCategory"
            style={categoryStyle}
        >
            <div
                ref={(e) => { drag(e); drop(e); }}
                className={"planer-line planer-category-title" + (canDrop ? " can-drop" : "") + (isOver ? " is-over" : "")}
                style={{ opacity: isDragging ? 0.5 : 1 }}
            >
                <p style={{maxWidth: 'calc(100% - 10rem)'}}>
                    {title || "(Kein Titel)"}
                    <span className="planer-line-addition">
                        <ToggleButton isActive={categoryItem.active_flag} callbackApiToggle={callbackApiToggle}/>
                        <EditButton callbackEdit={() => {
                            setEditModalItem(categoryItem)
                        }} canEdit={true}/>
                        <ButtonPlaceholder/>
                        <DelButton callbackDelete={() => {
                            setDeleteModalItem(categoryItem)
                        }}/>
                    </span>
                </p>
            </div>
            {groups.map((group) => (
                <Gruppe
                    key={group.id}
                    categoryItem={group}
                    setEditModalItem={setEditModalItem}
                    setUploadModalItem={setUploadModalItem}
                    setDeleteModalItem={setDeleteModalItem}
                    dropItemToSibling={dropItemToSibling}
                    dropItemToContainer={dropItemToContainer}
                    updateData={updateData}
                />
            ))}
        </div>
    );
};

export default Kategorie;
