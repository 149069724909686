import {useDrag} from "react-dnd";

function ThemaAuswahl() {
    const [{isThemaDragging}, dragThema] = useDrag(
        () => ({
            type: 'neues_thema',
            item: {
                type: 'neues_thema',
            },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            })
        }),
        []
    );

    return(
        <>
            <div className="neuesElement neuesThema auswahllisteLine" ref={dragThema}><p>Neues Thema</p></div>
        </>
    );
}

export default ThemaAuswahl;
