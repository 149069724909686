import {useDrag} from "react-dnd";

function GruppeAuswahl() {
    const [{isGruppeDragging}, dragGruppe] = useDrag(
        () => ({
            type: 'neue_gruppe',
            item: {
                type: 'neue_gruppe',
            },
            collect: (monitor) => ({
                isDragging: !!monitor.isDragging(),
            })
        }),
        []
    );

    return(
        <>
            <div className="neuesElement neueGruppe auswahllisteLine" ref={dragGruppe}><p>Neue Gruppe</p></div>
        </>
    );
}

export default GruppeAuswahl;
