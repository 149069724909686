import React, {useState, useEffect} from 'react';
import SkeletonForm from "./SkeletonForm";
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import crmApiConfig from "../../config/crmApiConfig";

function FileUploadForm({item, callbackSaveUpload, callbackClose}) {
    const [fileToUpload, setFileToUpload] = useState(null);
    const [filename, setFilename] = useState(item.file_name);
    const [hadFile, setHadFile] = useState(!!item.file_name);

    useEffect(() => {
        setFilename(item.file_name);
        setFileToUpload(null);
        setHadFile(!!item.file_name);
    }, [item]);

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFileToUpload(file);
            setFilename(file.name);
        }
    };

    const removeFile = (e) => {
        setFilename(null);
        setFileToUpload(null);
    }

    const callbackSave = () => {
        callbackSaveUpload(item, filename, fileToUpload);
    };

    return (
        <SkeletonForm callbackOk={callbackSave} callbackClose={callbackClose}>
            <h2>Kurs bearbeiten</h2>
            {
                (filename || hadFile) &&
                <>
                    <label>Bestehender Anhang</label><input disabled value={filename}/>
                    <div></div>
                    <a href={`${crmApiConfig.uploadsRoot}/${item.id}/${filename}`} target={'_blank'}>Download</a>
                    <div></div>
                    <div>
                        {
                            !filename ?
                                'Wird entfernt!' :
                                <button type="button" onClick={removeFile}>Entfernen</button>
                        }
                    </div>
                </>
            }
            <label>Neuer Anhang</label>
            <input type="file" onChange={handleFileChange}/>
        </SkeletonForm>
    );
}

export default FileUploadForm;
